var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.$store.state.loading,"elevation":"0"}},[_c('v-card-title',[_c('h3',[_vm._v("المكاتب")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$store.commit('offices/toggleDialog')}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" إضافة مكتب ")],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"ripple":false,"items":_vm.offices,"hide-default-footer":"","loading":_vm.$store.state.loading,"no-data-text":"لا يوجد","loading-text":"جار التحميل..."},on:{"click:row":function (_) {
            _vm.$router.push({
              name: 'office',
              params: {
                id: _.id,
              },
            });
          }},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.isActive ? 'success' : 'error',"text":""}},[_vm._v(" "+_vm._s(item.isActive ? "مفعل" : "غير مفعل")+" ")])]}},{key:"item.logoUrl",fn:function(ref){
          var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.$service.url + item.logoUrl,"contain":"","height":"75","width":"75"}})]}},{key:"item.numberPhone",fn:function(ref){
          var item = ref.item;
return [_c('span',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(item.numberPhone)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center align-content-end justify-end"},[_c('v-btn',{attrs:{"color":"primary","to":{
                name: 'office',
                params: {
                  id: item.id,
                },
              },"text":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"color":item.isActive ? 'error' : 'success',"text":""},on:{"click":function($event){return _vm.toggleActivation(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.isActive ? "mdi-close" : "mdi-check"))]),_vm._v(" "+_vm._s(item.isActive ? "إلغاء التفعيل" : "تفعيل")+" ")],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.editOffice(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" تعديل ")],1)],1)],1)],1)],1)]}}])})],1)],1),_c('Dialog',{on:{"refresh":function($event){return _vm.getOffices()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }