<template>
  <v-dialog persistent v-model="$store.state.offices.dialog" width="650">
    <v-card :loading="$store.state.loading" outlined>
      <v-card-title class="text-h5 my-3">
        {{ isEdit ? "تعديل مكتب " + item.name : "اضافة مكتب" }}
        <v-spacer></v-spacer>
        <v-btn
          @click="$store.commit('offices/toggleDialog')"
          text
          color="error"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-form ref="addOffice" v-model="vaild">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                hide-details="auto"
                label="اسم المكتب"
                v-model="inputs.name"
                required
                :rules="[$rules.required()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                hide-details="auto"
                label="عدد المستخدم"
                v-model="inputs.usersNumber"
                required
                :rules="[$rules.required()]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                hide-detials="auto"
                append-icon="mdi-phone"
                v-model="inputs.numberPhone"
                label="رقم الهاتف"
                dir="ltr"
                v-mask="'+964 ### ### ####'"
                :rules="[$rules.required()]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-file-input
                v-model="file"
                label="شعار المكتب"
                outlined
                hide-details
                accept="image/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="inputs.descrption"
                outlined
                label="الوصف"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$store.commit('offices/toggleDialog')"
          >
            إلغاء
          </v-btn>
          <v-btn color="primary" :disabled="!vaild" @click="addOffice()">
            حفظ
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.offices.isEdit;
    },
    item: {
      get() {
        return this.$store.state.offices.item;
      },
    },
  },
  data() {
    return {
      // isEdit: this.$store.state.offices.isEdit,
      // item: this.$store.state.offices.item,
      date: false,
      vaild: false,
      file: null,
      inputs: {
        name: "",
        descrption: "",
        numberPhone: "",
        logoUrl: null,
        usersNumber: 1,
      },
    };
  },
  methods: {
    addOffice() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch("uploadFile", this.file).then((url) => {
        if (typeof url === "string") {
          this.inputs.logoUrl = url;
        }
        if (typeof this.inputs.logoUrl === "object") {
          this.inputs.logoUrl = "";
        }
        if (this.isEdit) {
          this.$http
            .put("/offices/put/" + this.item.id, this.inputs)
            .then(() => {
              this.$store.commit("setLoading", false);
              this.$store.commit("offices/toggleDialog");
              this.$emit("refresh", true);
            });
        } else {
          this.$http.post("/offices/post", this.inputs).then(() => {
            this.$store.commit("setLoading", false);
            this.$store.commit("offices/toggleDialog");
            this.$emit("refresh", true);
          });
        }
      });
    },
  },
  watch: {
    "$store.state.offices.dialog"() {
      if (this.$store.state.offices.dialog == false) {
        if (this.$store.state.offices.isEdit) {
          this.$store.commit("offices/toggleEdit");
        } else {
          this.$refs.addOffice.reset();
        }
      } else {
        if (!this.$store.state.offices.isEdit) {
          this.$refs.addOffice.reset();
        }
      }
      if (this.isEdit) {
        this.inputs = this.item;
        let fileName = this.item.logoUrl.split("_");
        fileName = fileName[fileName.length - 1];
        this.file = new File(["1"], fileName);
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>