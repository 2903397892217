<template>
  <div>
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <h3>المكاتب</h3>
        <v-spacer></v-spacer>
        <v-btn @click="$store.commit('offices/toggleDialog')" color="primary">
          <v-icon>mdi-plus</v-icon>
          إضافة مكتب
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :ripple="false"
          :items="offices"
          hide-default-footer
          :loading="$store.state.loading"
          no-data-text="لا يوجد"
          loading-text="جار التحميل..."
          @click:row="
            (_) => {
              $router.push({
                name: 'office',
                params: {
                  id: _.id,
                },
              });
            }
          "
        >
          <template v-slot:item.isActive="{ item }">
            <v-chip :color="item.isActive ? 'success' : 'error'" text>
              {{ item.isActive ? "مفعل" : "غير مفعل" }}
            </v-chip>
          </template>
          <template v-slot:item.logoUrl="{ item }">
            <v-img
              :src="$service.url + item.logoUrl"
              contain
              height="75"
              width="75"
            />
          </template>
          <template v-slot:item.numberPhone="{ item }">
            <span dir="ltr">
              {{ item.numberPhone }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-center align-content-end justify-end">
              <v-btn
                color="primary"
                :to="{
                  name: 'office',
                  params: {
                    id: item.id,
                  },
                }"
                text
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-btn
                      @click="toggleActivation(item)"
                      :color="item.isActive ? 'error' : 'success'"
                      text
                    >
                      <v-icon>{{
                        item.isActive ? "mdi-close" : "mdi-check"
                      }}</v-icon>
                      {{ item.isActive ? "إلغاء التفعيل" : "تفعيل" }}
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn @click="editOffice(item)" text>
                      <v-icon>mdi-pencil</v-icon>
                      تعديل
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Dialog @refresh="getOffices()" />
  </div>
</template>
<script>
import Dialog from "./Dialog.vue";
export default {
  name: "Offices",
  components: {
    Dialog,
  },
  data() {
    return {
      offices: [],
      headers: [
        { text: "الشعار", value: "logoUrl" },
        { text: "الاسم", value: "name" },
        { text: "عدد الاعضاء", value: "usersCount" },
        { text: "عدد الشركات", value: "companiesCount" },
        { text: "الحالة", value: "isActive" },
        { text: "الوصف", value: "descrption" },
        { text: "الاجراءات", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    toggleActivation(office) {
      this.$store.commit("setLoading", true);
      this.$http
        .delete("/Offices/toggleActivation/" + office.id)
        .finally(() => {
          this.getOffices();
        });
    },
    getOffices() {
      this.$store.commit("setLoading", true);
      this.$http
        .get("/offices")
        .then((res) => {
          this.offices = res.data.data;
          for (var i = 0; i < this.offices.length; i++) {
            Object.keys(this.offices[i]).forEach((key) => {
              if (typeof this.offices[i][key] == "number") {
                this.offices[i][key] =
                  "" + this.$service.formatNumber(this.offices[i][key]);
              }
            });
          }
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    editOffice(office) {
      let temp = office;
      this.$store.commit("offices/updateItem", temp);
      
      this.$store.commit("offices/toggleEdit");
      
      this.$store.commit('offices/toggleDialog')
    },
  },
  created() {
    this.getOffices();
  },
};
</script>
<style>
tr:hover {
  cursor: pointer !important;
}
td:not(.v-data-table__mobile-row) {
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>